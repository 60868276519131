import {fetchQuery} from "@scandipwa/scandipwa/src/util/Request/Query";
import GtmQuery from '../query/Gtm.query';
import {isHomePageUrl} from 'Util/Url';
import browserHistory from 'Util/History';
import BrowserDatabase from 'Util/BrowserDatabase';
import {PAYMENT_TOTALS} from "Route/Checkout/Checkout.config";
import {getCartId, setCartId} from 'Util/Cart';
import {Field} from 'Util/Query';
import getStore from 'Util/Store';
import {CART_TOTALS} from "@scandipwa/scandipwa/src/store/Cart/Cart.reducer";
class GtmPlugin {

    allowedRequests = [
        'catalogsearch',
        'checkoutsuccess'
    ];

    requestGTMData(source, id = 0, search = '') {
        window.requestGTMData = false;
        let params = new URLSearchParams(window.location.search);
        const page = params.get('page') || 1;
        const sortKey = params.get('sortKey');
        const sortDirection = params.get('sortDirection');
        const customFilters = params.get('customFilters');

        // console.log(params);
        // console.log(page);
        // console.log(sortKey);
        // console.log(sortDirection);
        // console.log(customFilters);



        if (this.allowedRequests.includes(source)) {

        }else {
            if (typeof window.gtmSource === 'undefined') {
                window.gtmSource = source;
            }else {
                return;
            }
        }

        this.getGTMdata(JSON.stringify({
            source: source,
            id: id,
            page:page,
            sort: {
                sortKey:sortKey,
                sortDirection:sortDirection,
            },
            customFilters:customFilters,
            search:search
        }));
    }
    async insertHeadScripts() {
        await this.getGTMHead('GTM');
        await this.getGTMHead('GA');
        await this.getGTMHead('FbPixel');
    }

    parseScripts(module, scripts, position = 'head', type = false) {
        if (window.dataLayer !== undefined && window.google_tag_manager !== undefined) {
            window.dataLayer.length = 0;
            let gtmContainerReg = /GTM-/i;
            for (const gtmKey of Object.keys(window.google_tag_manager)) {
                if (gtmContainerReg.test(gtmKey) && window.google_tag_manager[gtmKey].dataLayer
                    && window.google_tag_manager[gtmKey].dataLayer.reset
                ) {
                    // console.log(gtmKey);
                    window.google_tag_manager[gtmKey].dataLayer.reset();
                }
            }
            gtmContainerReg = /G-/i;
            for (const gtmKey of Object.keys(window.google_tag_manager)) {
                if (gtmContainerReg.test(gtmKey) && window.google_tag_manager[gtmKey].dataLayer
                    && window.google_tag_manager[gtmKey].dataLayer.reset
                ) {
                    // console.log(gtmKey);
                    window.google_tag_manager[gtmKey].dataLayer.reset();
                }
            }
        }
        // console.log(module + ' - ' + position);
        // console.log(scripts);





        scripts = document.createRange().createContextualFragment(scripts).children;

        // console.log(scripts);
        Object.entries(scripts).map(([index, script]) => {
            script.id = module.toLowerCase() + '_' + index;
            // console.log(position);
            // console.log(script);
            // console.log(document.getElementById(script.id));
            // if (!document.getElementById(script.id)) {
                document.querySelectorAll('head [src*="googletagmanager"]').forEach( n => n.remove() );
                document.querySelectorAll('head [src*="fbevents"]').forEach( n => n.remove() );
                document.querySelectorAll('[id*="'+script.id+'"]').forEach( n => n.remove() );
                document[position].prepend(script);

            // }
        });
    }

    async insertGTMCodeScripts() {
        await this.getGTMCode('GTM', 'category', 8);
        // await this.getGTMCode('GA', 'category', 8);
        // await this.getGTMCode('FbPixel', 'category', 8);
    }

    async getGTMdata(options) {
        fetchQuery(
            GtmQuery.getGTMData(options)
        ).then(
            (
                async (data) => {
// console.clear();
                    // console.log(data);

                    // const allowedRequests = [
                    //     'add_to_cart',
                    //     'remove_from_cart',
                    //     'increase_qty',
                    //     'decrease_qty',
                    //     'catalogsearch',
                    //     'checkoutsuccess'
                    // ];
                    const decodedOptions = JSON.parse(options);
                    const {source} = decodedOptions;

                    // if (allowedRequests.includes(source)) {
                    //
                    // }else {
                    //     if (typeof window.gtmSource === 'undefined') {
                    //         window.gtmSource = source;
                    //     }else {
                    //         return;
                    //     }
                    // }



                    // console.log('gtm_source');
                    // console.log(source);


                    const {gtmData} = data;

                    // if (typeof window.recievedGtmData !== 'undefined' && window.recievedGtmData === gtmData) {
                    //     return;
                    // }
                    // window.recievedGtmData = gtmData;
                    //
                    //
                    // console.log(gtmData);

                    const GTMData = JSON.parse(gtmData.gtm_data);

                    if (!GTMData) {
                        return;
                    }

                    // console.log('################################');
                    // console.log(GTMData);
                    // console.log(window.location.hostname);

                    const {head = false, code = false} = GTMData;
                    // console.log('################################');
                    // console.log(window.recievedGtmData);
                    // console.log(code);
                    // if (typeof window.recievedGtmData !== 'undefined' && window.recievedGtmData === code) {
                    //     console.log('return, not injecting');
                    //     return;
                    // }
                    // console.log('################################');
                    // window.recievedGtmData = code;


                    if (head && window.location.hostname === 'localhost') {
                        // console.log(head);
                        // let scriptDomId = '_head_' + decodedOptions.source;
                        let scriptDomId = '_head';
                        if (!!head.GTM) {
                            this.parseScripts('gtm' + scriptDomId, head.GTM);
                        }
                        if (!!head.GA) {
                            this.parseScripts('ga' + scriptDomId, head.GA);
                        }
                        if (!!head.FbPixel) {
                            this.parseScripts('fbpixel' + scriptDomId, head.FbPixel);
                        }
                    }
                    if (code) {
                        // console.log(code);

                        // let scriptDomId = '_' + decodedOptions.source + '_' + decodedOptions.id;
                        // let scriptDomId = '_body_' + decodedOptions.source;
                        let scriptDomId = '_body' ;
                        // document.querySelectorAll('[id*="'+scriptDomId+'"]').forEach( n => n.remove() );
                        if (!!code.GTM) {
                            this.parseScripts('gtm' + scriptDomId, code.GTM, 'body');
                        }
                        if (!!code.GA) {
                            this.parseScripts('ga' + scriptDomId, code.GA, 'body');
                        }
                        if (!!code.FbPixel) {
                            this.parseScripts('fbpixel' + scriptDomId, code.FbPixel, 'body');
                        }

                    }


                    // if (!!head) {
                    //     // console.log(head);
                    //     let scripts = document.createRange().createContextualFragment(head).children;
                    //     Object.entries(scripts).map(([index, script]) => {
                    //         // script.id = type.toLowerCase() + '_' + action + '_' + index;
                    //         console.log(script);
                    //         document.body.prepend(script);
                    //     });
                    //     // const script = document.createElement('script');
                    //     // script.innerHTML = head;
                    //     // document.head.insertBefore(script, document.head.childNodes[0]);
                    // }

                    // console.log(window);
                }),
            (err) => {
                // console.log(err);
            }
        );
    }

    async getGTMHead(source) {
        fetchQuery(
            GtmQuery._mpGTMHead(source)
        ).then(
            (
                async (data) => {

                    // console.log(data);

                    const {[source]: {head}} = data;

                    if (!!head) {
                        // console.log(head);
                        let scripts = document.createRange().createContextualFragment(head).children;
                        Object.entries(scripts).map(([index, script]) => {
                            // script.id = type.toLowerCase() + '_' + action + '_' + index;
                            // console.log(script);
                            document.body.prepend(script);
                        });
                        // const script = document.createElement('script');
                        // script.innerHTML = head;
                        // document.head.insertBefore(script, document.head.childNodes[0]);
                    }

                    // console.log(window);
                }),
            (err) => {
                // console.log(err);
            }
        );
    }

    async getGTMCode(type, action, id) {
        fetchQuery(
            GtmQuery._mpGTMCode(type, action, id)
        ).then(
            (
                async (data) => {

                    // console.log(data);

                    const {mpGTMCode: {code}} = data;

                    if (!!code) {
                        // console.log(head);
                        let scripts = document.createRange().createContextualFragment(code).children;
                        Object.entries(scripts).map(([index, script]) => {
                            // script.id = type.toLowerCase() + '_' + action + '_' + index;
                            // console.log(script);
                            document.body.prepend(script);
                        });
                        // const script = document.createElement('script');
                        // script.innerHTML = head;
                        // document.head.insertBefore(script, document.head.childNodes[0]);
                    }

                    // console.log(window);
                }),
            (err) => {
                // console.log(err);
            }
        );
    }

    renderCategoryPage = (args, callback, instance) => {
        const {isCurrentCategoryLoaded, category: {id = 0} = {}} = instance.props;
// console.log(isCurrentCategoryLoaded);
        if (!isCurrentCategoryLoaded) {
            return callback(...args);
        }
        // console.clear();
        // console.log(instance.state);
        // console.log(instance.props);
        // console.log(id);

        if (!id || !window.requestGTMData) {
            return callback(...args);
        }
        // console.log(window.requestGTMData);
        this.requestGTMData('category', id);
        // this.insertGTMCodeScripts();
        // console.log(window);
        return callback(...args);
    };

    componentDidMountCategoryPage = (args, callback, instance) => {
        // console.clear();
        // this.insertGTMCodeScripts();
        // console.log(window);
        return callback(...args);
    };


    componentWillUnmountCategoryPage = (args, callback, instance) => {
        // console.clear();
        // console.log(window);
        return callback(...args);
    };

    componentDidMountRouter = (args, callback, instance) => {
        window.requestGTMData = true;
        if (window.location.pathname === '/') {
            this.requestGTMData('home', 0);
        }
        // let asd = callback.apply(instance, args);
        // console.clear();
        // console.log(instance.state);
        // console.log(instance.props);
        // console.log(args);
        const cartTotals = BrowserDatabase.getItem(CART_TOTALS) || {};
        // console.log(cartTotals);
        // const realState = getStore().getState();
        // console.log(realState);
        // this.insertHeadScripts();
        // console.log(window);
        browserHistory.listen( location =>  {
            // console.clear();
            // console.log(location);
            // console.log(instance.state);
            // console.log(instance.props);
            //
            const {pathname} = location;
            const {currentUrl} = instance.props;
            //
            if (pathname !== currentUrl) {
                // console.clear();
                // console.log(location);
                // console.log(instance.state);
                // console.log(instance.props);
                let {pathname, state: {category = 0, product: {id} = {id:0}} = {}} = location;

                let source = 'product';

                if (id === 0) {
                    source = 'category';
                    id = category;

                }

                if (pathname.includes('/search/')) {
                    source = 'search';
                }
                // console.log(source)

                // const state = getStore().getState();
                // console.log(state);

                // this.requestGTMData(source, id);
                window.requestGTMData = true;
                delete window.gtmSource;

                // let params = new URLSearchParams(window.location.search);
                // const page = params.get('page') || 1;
                // const sortKey = params.get('sortKey');
                // const sortDirection = params.get('sortDirection');
                // const customFilters = params.get('customFilters');
                //
                // console.log(params);
                // console.log(page);
                // console.log(sortKey);
                // console.log(sortDirection);
                // console.log(customFilters);
                //
                // this.getGTMdata(JSON.stringify({
                //     source: source,
                //     id: id,
                //     page:page,
                //     sort: {
                //         sortKey:sortKey,
                //         sortDirection:sortDirection,
                //     },
                //     customFilters:customFilters
                // }));


                // console.log(id);
                // console.clear();
                // const state = getStore().getState();
                // console.log(state);
                // console.log('router changes');
                // this.injectScripts(location);
                delete window.checkoutsuccess;
            }
            // window.dispatchEvent(new Event("resize"));
            // this.setState({injected:false});
            // let interval = setInterval(() => {
            //     window.dispatchEvent(new Event("resize"));
            //     clearInterval(interval);
            // }, 1);
        });
        return callback(...args);
    };


    componentWillUnmountRouter = (args, callback, instance) => {
        // console.clear();
        return callback(...args);
    };

    renderMainContentCartPage = (args, callback, instance) => {
        // console.clear();
        const cartTotals = BrowserDatabase.getItem(CART_TOTALS) || {};

        // console.log(cartTotals);
        window.currentRoute = 'cart'
        // console.log(window.currentRoute);
        window.requestGTMData = true;
        if (window.location.pathname.includes('/cart')) {
            this.requestGTMData('checkoutcart', (cartTotals.id) ? cartTotals.id: 0);
        }
        // this.getGTMHead('GTM');
        // this.getGTMHead('GA');
        // this.getGTMHead('FbPixel');
        return callback(...args);
    };


    componentWillUnmountCartPage = (args, callback, instance) => {
        delete window.currentRoute;
        // console.log(window.currentRoute);
        return callback(...args);
    };

    renderStepCheckout = (args, callback, instance) => {
        // console.clear();
        const cartTotals = BrowserDatabase.getItem(CART_TOTALS) || {};
        // console.log(cartTotals);
        // console.log(window.currentRoute);
        window.requestGTMData = true;
        if (window.location.pathname.includes('/checkout')) {
            this.requestGTMData('checkoutindex', (cartTotals.id) ? cartTotals.id: 0);
            window.currentRoute = 'checkout'

        }

        return callback(...args);
    };

    checkoutSuccessRender = (args, callback, instance) => {
        // console.clear();
        // console.log(instance.state);
        // console.log(instance.props);
        // console.log(window.currentRoute);

        if (window.checkoutsuccess === 'checkoutsuccess') {
            return callback(...args);
        }
        window.checkoutsuccess = 'checkoutsuccess';

        const {orderID} = instance.props;

        this.getGTMdata(JSON.stringify({
            source: 'checkoutsuccess',
            id: orderID
        }));

        // window.currentRoute = 'checkout-success'
        // window.requestGTMData = true;
        // if (window.location.pathname.includes('/checkout/success')) {
        //     this.requestGTMData('checkoutsuccess', orderID);
        // }
        return callback(...args);
    };


    componentWillUnmountCheckout = (args, callback, instance) => {
        delete window.currentRoute;
        // console.log(window.currentRoute);
        return callback(...args);
    };

    componentDidUpdateRouter = (args, callback, instance) => {
        // console.clear();
        // console.log(args);
        // console.log(callback);
        // console.log(instance.state);
        // console.log(instance.props);
        // console.log('router changes');
        // console.log(window.currentRoute);
        // console.log(window);
        return callback(...args);
    };

    renderCategoryDetailsSearchPage = (args, callback, instance) => {
        // console.log(args);
        // console.log(instance.state);
        // console.log(instance.props);
        const {search} = instance.props;
        // console.log(search);
        const cartTotals = BrowserDatabase.getItem(CART_TOTALS) || {};

        // console.log(cartTotals);
        window.currentRoute = 'searchpage'
        // console.log(window.currentRoute);
        window.requestGTMData = true;
        if (window.location.pathname.includes('/search')) {
            this.requestGTMData('catalogsearch', (cartTotals.id) ? cartTotals.id: 0, search);
        }
        return callback(...args);
    };
    renderProductPageContent = (args, callback, instance) => {
        // console.clear();
        // console.log(args);
        // console.log(instance.state);
        // console.log(instance.props);
        const {activeProduct: {id}} = instance.props;

        if (!id || !window.requestGTMData) {
            return callback(...args);
        }

        window.requestGTMData = true;
            this.requestGTMData('product', id);

        return callback(...args);
    };

    addProductToCart = async (args, callback, instance) => {
        // console.info('AddToCart');
        // console.info(args);
        // console.info(instance.state);
        // console.info(instance.props);
        this.getGTMdata(JSON.stringify({
            source: 'add_to_cart',
            product:args[1].products
        }));
        return callback(...args);
    };

    removeProductFromCart = async (args, callback, instance) => {
        // console.info('RemoveFromCart');
        // console.info(args);
        // console.info(instance.state);
        // console.info(instance.props);
        const cartTotals = BrowserDatabase.getItem(CART_TOTALS) || {};

        this.getGTMdata(JSON.stringify({
            source: 'remove_from_cart',
            item_id: args[1],
            cart_id: (cartTotals.id) ? cartTotals.id: 0
        }));
        // return;
        return callback(...args);
    };

    removeAllItemsFromCart = async (args, callback, instance) => {
        // console.info('removeAllItemsFromCart');
        // console.info(args);
        // console.info(instance.state);
        // console.info(instance.props);
        const cartItems = args[1];
        let newItems = [];
        Object.values(args[1]).forEach((key) => {
            newItems.push(
                {
                    cart_item_uid: key.cart_item_uid,
                    quantity: key.quantity,
                }
            );
        })

        let newArgs = args;
        newArgs[1] = newItems;

        // console.log(newArgs);


        // return ;

        this.getGTMdata(JSON.stringify({
            source: 'remove_all_from_cart',
            cart_items: cartItems
        }));

        // return ;
        return callback(...newArgs);
    };

    handleChangeQuantity = async (args, callback, instance) => {
        // console.info('handleChangeQuantity');
        // console.info(args);
        // console.info(instance.state);
        // console.info(instance.props);
        const newQty = args[0];
        const {item: {id, quantity}} = instance.props;
        const cartTotals = BrowserDatabase.getItem(CART_TOTALS) || {};
        // console.log(newQty);
        // console.log(quantity);

        let qty = 0;
        if (newQty > quantity) {
            qty = newQty - quantity
            // console.log(qty);
            this.getGTMdata(JSON.stringify({
                source: 'increase_qty',
                item_id: id,
                quantity: qty,
                cart_id: (cartTotals.id) ? cartTotals.id: 0
            }));
        }
        if (newQty < quantity) {
            qty = quantity - newQty
            // console.log(qty);
            this.getGTMdata(JSON.stringify({
                source: 'decrease_qty',
                item_id: id,
                quantity: qty,
                cart_id: (cartTotals.id) ? cartTotals.id: 0
            }));
        }

        return callback(...args);

    };

    addItemToWishlist = async (args, callback, instance) => {
        // console.info('addItemToWishlist');
        // console.info(args);
        // console.info(instance.state);
        // console.info(instance.props);
        this.getGTMdata(JSON.stringify({
            source: 'add_to_wishlist',
            product:args[1].items
        }));
        return callback(...args);
    };

    removeItemFromWishlist = async (args, callback, instance) => {
        // console.info('removeItemFromWishlist');
        // console.info(args);
        // console.info(instance.state);
        // console.info(instance.props);
        // this.getGTMdata(JSON.stringify({
        //     source: 'add_to_wishlist',
        //     product:args[1].items
        // }));
        return callback(...args);
    };

}


const {

    componentDidMountCategoryPage,
    componentWillUnmountCategoryPage,
    componentDidMountRouter,
    componentWillUnmountRouter,
    renderMainContentCartPage,
    componentWillUnmountCartPage,
    renderStepCheckout,
    componentWillUnmountCheckout,
    componentDidUpdateRouter,
    // getStaticReducers,
    // mapDispatchToProps,
    // BEFORE_ITEMS_TYPE,
    // getCartQuery,
    // _getCartTotalsFields,
    // checkoutSuccess
    renderCategoryPage,
    renderCategoryDetailsSearchPage,
    renderProductPageContent,
    checkoutSuccessRender,
    addProductToCart,
    removeProductFromCart,
    removeAllItemsFromCart,
    handleChangeQuantity,
    addItemToWishlist,
    removeItemFromWishlist
} = new GtmPlugin();

export default {
    'Component/CartItem/Container': {
        'member-function': {
            handleChangeQuantity
        }
    },
    'Store/Cart/Dispatcher': {
        'member-function': {
            addProductToCart,
            removeProductFromCart,
            removeAllItemsFromCart
        }
    },
    'Route/CategoryPage/Component': {
        'member-function': {
            componentDidMount: componentDidMountCategoryPage,
            componentWillUnmount: componentWillUnmountCategoryPage,
            renderContent: renderCategoryPage
        }
    },
    'Route/ProductPage/Component': {
        'member-function': {
            renderProductPageContent: renderProductPageContent
        }
    },


    'Route/SearchPage/Component': {
        'member-function': {
            renderCategoryDetails: renderCategoryDetailsSearchPage,
        }
    },
    'Route/CartPage/Component': {
        'member-function': {
            renderMainContent: renderMainContentCartPage,
            componentWillUnmount: componentWillUnmountCartPage
        }
    },
    'Route/Checkout/Component': {
        'member-function': {
            renderStep: renderStepCheckout,
            // renderDetailsStep: renderDetailsStepCheckout,
            componentWillUnmount: componentWillUnmountCheckout
        }
    },
    'Component/Router/Container': {
        'member-function': {
            componentDidMount: componentDidMountRouter,
            componentWillUnmount: componentWillUnmountRouter,
            componentDidUpdate: componentDidUpdateRouter
        }
    },
    // 'Store/Index/getStaticReducers': {
    //     function: getStaticReducers
    // },
    // 'Component/Router/Container/mapDispatchToProps': {
    //     function: mapDispatchToProps
    // },
    // 'Component/Router/Component': {
    //     'member-property': {
    //         BEFORE_ITEMS_TYPE
    //     }
    // },
    // 'Query/Cart/Query': {
    //     'member-function': {
    //         // getCartQuery,
    //         _getCartTotalsFields
    //     }
    // },
    'Component/CheckoutSuccess/Component': {
        'member-function': {
            render:checkoutSuccessRender
        }
    },
    'Store/Wishlist/Dispatcher': {
        'member-function': {
            addItemToWishlist,
            removeItemFromWishlist
        }
    },
};
