import {
    SHOW_VAT_NUMBER_REQUIRED as SOURCE_SHOW_VAT_NUMBER_REQUIRED,
    SHOW_VAT_NUMBER_OPTIONAL as SOURCE_SHOW_VAT_NUMBER_OPTIONAL,
    CONFIRMATION_REQUIRED as SOURCE_CONFIRMATION_REQUIRED,
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.config';

//TODO: implement SHOW_VAT_NUMBER_REQUIRED
export const SHOW_VAT_NUMBER_REQUIRED = SOURCE_SHOW_VAT_NUMBER_REQUIRED;

//TODO: implement SHOW_VAT_NUMBER_OPTIONAL
export const SHOW_VAT_NUMBER_OPTIONAL = SOURCE_SHOW_VAT_NUMBER_OPTIONAL;

//TODO: implement CONFIRMATION_REQUIRED
export const CONFIRMATION_REQUIRED = SOURCE_CONFIRMATION_REQUIRED;
