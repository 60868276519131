const _getStoreConfigFields = (args, callback) => {
    const originalFields = callback(...args);
    return [
        ...originalFields,
        'gst_is_enabled',
        'gst_is_visible',
        'gst_is_required'
    ];
};

const getGstFields = (args, callback, instance) => [
    ...callback(...args),
    'cgst_charge',
    'cgst_percent',
    'sgst_charge',
    'sgst_percent',
    'igst_charge',
    'igst_percent'
];

const getOrderGstFields = (args, callback, instance) => [
    ...callback(...args),
    'shipping_sgst_charge',
    'percent_shipping_sgst_charge',
    'shipping_cgst_charge',
    'percent_shipping_cgst_charge',
    'shipping_igst_charge',
    'percent_shipping_igst_charge',
    'buyer_gst_number',
    'cgst_charge',
    'sgst_charge',
    'igst_charge',
    'gst_tax_type',
    'gst_shipping_tax_type'

];

const _getCartTotalsFields = (args, callback, instance) => [
    ...callback(...args),
    'in_state',
    'shipping_sgst_charge',
    'percent_shipping_sgst_charge',
    'shipping_cgst_charge',
    'percent_shipping_cgst_charge',
    'shipping_igst_charge',
    'cgst_charge',
    'sgst_charge',
    'igst_charge',
    'excl_price',
    'buyer_gst_number',
    'ship_excl_price'
];

const _getCustomerFields = (args, callback, instance) => [
    ...callback(...args),
    'buyergst'
];
// export const _getCartTotalsFields = (args, callback, instance) => {
//     let fields = callback.apply(instance, args);
//     fields.push(
//         'cgst_charge'
//     );
//     console.clear();
// console.log(fields);
//
//     return fields;
// };

export default {
    'Query/Order/Query': {
        'member-function': {
            _getOrderItemProductsFields: getGstFields,
            _getInvoiceItemProductsFields: getGstFields,
            _getOrderItemTotalFields: getOrderGstFields
        }
    },
    'Query/Cart/Query': {
        'member-function': {
            // _getCartTotalsFields: _getCartTotalsFields,
        }
    },
    'Query/MyAccount/Query': {
        'member-function': {
            // _getCustomerFields: _getCustomerFields,
        }
    },
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields
        }
    }
};
