import {Field} from 'Util/Query';

export class GtmQuery {

    getGTMData(options) {
        // console.log(options);
        return new Field('gtmData')
            .addFieldList([
                'gtm_data'
            ])
            .addArgument('source', 'String', options)
    }

    _mpGTMHead(source) {
        // const { orderId, page = 1 } = options || {};
        const sourceField = new Field('mpGTMHead');

        // if (orderId) {
            return sourceField
                .addArgument('filters', 'MpGTMHeadFilters', { type: source })
                .addFieldList(['head'])
                .setAlias(source);
        // }

    }

    _mpGTMCodeHome() {
        // const { orderId, page = 1 } = options || {};
        const sourceField = new Field('mpGTMCodeHome');

        // if (orderId) {
        return sourceField
            .addFieldList(['code']);
        // }

    }

    _mpGTMCode(type, action, id, currentPage = 1, pageSize = 20, sort = {}) {
        // console.clear();
        // console.log(type, action, id, currentPage, pageSize, sort);
        // const { type, action, id, pageSize = 100, currentPage = 1 } = options || {};
        const sourceField = new Field('mpGTMCode');

        // if (orderId) {
        return sourceField
            .addArgument('filters', 'MpGTMCodeFilters', {
                type: type,
                action: action,
                id: id,
                pageSize: pageSize,
                currentPage: currentPage,
                sort: sort
            })
            .addFieldList(['code']);
        // }

    }

    getAddProductToCartScripts([cartId, cartItems]) {
        // return new Field('addSimpleProductsToCart')
        //     .addField(new Field('cart')
        //         .addFieldList(this._getCartFieldList())).addArgument(
        //         'input', 'SetRzpPaymentDetailsOnCartInput!',
        //         {
        //             'cart_id': cart_id,
        //             'rzp_payment_id': razorpay_payment_id,
        //             'rzp_order_id': razorpay_order_id,
        //             'rzp_signature': razorpay_signature,
        //         }
        //     );
        console.log('query');
        console.log(cartId);
        console.log(cartItems);

        let test = {};

        test['sku'] = 'A10500005200';
        test['selected_options'] = 'A10500005203';
        test['quantity'] = 1;
        console.log(test);

        let addedItem = Object.assign({}, ...Object.entries({...cartItems[0]}).map(([a,b]) => ({ [a]: b })))
console.log(addedItem);
        // let data = [];
        // let temp = [];
        // temp.push('quantity');
        // data.push(temp);
        return new Field('addSimpleProductsToCart').addArgument(
            'input', 'AddSimpleProductsToCartInput!',
            {
                'cart_id': cartId,
                'cart_items': [{
                    data:addedItem
                }]
            }
        ).addField(new Field('cart').addFieldList(
            [
                'gtm_add_to_cart',
                'ga_add_to_cart',
                'fb_add_to_cart',
                // 'gtm_remove_from_cart',
                // 'ga_remove_from_cart'
            ]
        ));
            // .addArgument('cart_id', 'String!', cartId)
            // .addArgument('cart_items', '[CartItemInput!]!', cartItems);
    }

    _mpGTMCodeAddOrRemoveCart(cartId, cartItems) {
        console.log('query');
        console.log(cartId);
        console.log(cartItems);
        // const { orderId, page = 1 } = options || {};
        const sourceField = new Field('mpGTMCodeAddOrRemoveCart');

        // if (orderId) {
        return sourceField
            .addFieldList(['code'])
            .addArgument('cartId', 'String!', cartId)
            .addArgument('cartItems', '[CartItemInput!]!', cartItems);
        // }

    }
}

export default new GtmQuery();
