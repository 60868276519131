import {appendWithStoreCode} from 'Util/Url';
import browserHistory from 'Util/History';
import history from 'Util/History';
import BrowserDatabase from 'Util/BrowserDatabase';
import {getCartId, setCartId} from 'Util/Cart';
import {Field} from 'Util/Query';
import getStore from 'Util/Store';

let nativeAddToCart;
let nativeUnselectedOptions;

async function redirectToCart() {
    // console.clear();
    // console.log(nativeUnselectedOptions);
    try {
        if (!nativeUnselectedOptions.length) {
            BrowserDatabase.setItem(true, 'buyNowActive');
            history.replace(appendWithStoreCode('/checkout'));
        }else {
            BrowserDatabase.setItem(false, 'buyNowActive');
        }
    }catch (e) {
        BrowserDatabase.setItem(false, 'buyNowActive');
    }
    await nativeAddToCart();
    // if (!nativeUnselectedOptions.length) {
    //     // BrowserDatabase.setItem(true, 'buyNowActive');
    //     // history.replace(appendWithStoreCode('/cart'));
    // }

}

class BuyNowPlugin {

    componentDidMount = (args, callback, instance) => {
        // console.clear();
        // console.log(window.location.pathname);
        if (window.location.pathname === '/') {
            BrowserDatabase.setItem(false, 'buyNowActive');
            const cartId = BrowserDatabase.getItem('currentCartId');
            // console.log(cartId);
            if (cartId) {
                setCartId(cartId);
                try {
                    // console.log(instance);
                    const {init} = instance.props;
                    init();
                } catch (e) {
                }
            }

        }
        // console.log(BrowserDatabase.getItem('buyNowActive'));
        // if (BrowserDatabase.getItem('buyNowActive')) {
        //     console.clear();
        //     console.log(window.location.pathname);
        //     console.log(window.location.pathname.indexOf('cart'));
        //     console.log(window.location.pathname.indexOf('checkout'));
        //     if (window.location.pathname.indexOf('cart') !== 1 || window.location.pathname.indexOf('checkout') !== 1) {
        //         history.replace(appendWithStoreCode('/cart'));
        //     }
        //
        // } else {
        //     BrowserDatabase.setItem(false, 'buyNowActive');
        //     BrowserDatabase.deleteItem('currentCartId');
        // }

        browserHistory.listen(location => {
            // console.clear();
            // console.log(location);
            // console.log(BrowserDatabase.getItem('buyNowActive'));
            window.dispatchEvent(new Event("resize"));
            if (window.location.pathname.indexOf('cart') === 1 && BrowserDatabase.getItem('buyNowActive') || window.location.pathname.indexOf('checkout') === 1 && BrowserDatabase.getItem('buyNowActive')) {
                return;
            }
            // console.clear();
            // console.log(location);
            // console.log(BrowserDatabase.getItem('buyNowActive'));
            if (BrowserDatabase.getItem('buyNowActive')) {
                const cartId = BrowserDatabase.getItem('currentCartId');
                // console.log(cartId);
                if (cartId) {
                    setCartId(cartId);
                    try {
                        // console.log(instance);
                        const {init} = instance.props;
                        init();
                    } catch (e) {
                    }
                }

            }
            BrowserDatabase.setItem(false, 'buyNowActive');
            BrowserDatabase.deleteItem('currentCartId');
        });
        // console.log(BrowserDatabase.getItem('buyNowActive'));
        return callback(...args);
    };

    renderAddToCartButton = (args, callback, instance) => {

        const {
            addToCart,
            inStock,
            product: {url},
        } = instance.props;

        const {
            className
        } = instance;

        let actions = callback(...args);
// console.clear();
//         console.log(window.currentRoute);
//         console.log(inStock);
//         console.log(className);


        if (window.currentRoute !== 'product' || !inStock || className !== 'ProductActions') {
            return actions;
        }

        if (window.location.pathname === url) {
            nativeAddToCart = addToCart;
            const {
                parameters,
            } = instance.props;
            const {product: {configurable_options = {}}} = instance.props;
            nativeUnselectedOptions = Object.keys(configurable_options).reduce((accumulator, value) => {
                if (!parameters[value]) {
                    accumulator.push(value);
                }

                return accumulator;
            }, []);
        }

        // try {
        //     if (!nativeUnselectedOptions.length) {
        //         BrowserDatabase.setItem(true, 'buyNowActive');
        //         // history.replace(appendWithStoreCode('/cart'));
        //     }else {
        //         BrowserDatabase.setItem(false, 'buyNowActive');
        //     }
        // }catch (e) {
        //     BrowserDatabase.setItem(false, 'buyNowActive');
        // }



        // console.log(actions);

        // actions.props.children.props.isDisabled = nativeUnselectedOptions.length;

        const state = getStore().getState();
        const {
            magenear_buynow_button_label
        } = state.ConfigReducer;

        return (
            <>
                {actions}
                <button
                    // disabled={nativeUnselectedOptions.length}
                    className="AddToCart"
                    block="Button AddToCart"
                    elem="BuyNow"
                    onClick={redirectToCart}
                >
                    <span>{magenear_buynow_button_label}</span>
                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.25 13.5L11.75 9L7.25 4.5" stroke="white" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>
                </button>
            </>
        )
    };

    addProductToCart = async (args, callback, instance) => {
        console.info('AddToCart');
        console.info(args);
        console.info(BrowserDatabase.getItem('buyNowActive'));
        const dispatch = args[0];
        const currentCartId = args[1].cartId;
        let cartId = currentCartId || getCartId();
        let newArgs = args;
        if (BrowserDatabase.getItem('buyNowActive')) {
            BrowserDatabase.setItem(cartId, 'currentCartId');
            cartId = await instance.createGuestEmptyCart(dispatch);
            console.log('buy now');
            console.log(cartId);
        } else {
            cartId = BrowserDatabase.getItem('currentCartId') || getCartId();
            console.log('add to cart');
            console.log(cartId);
            setCartId(cartId);
        }
        newArgs[1].cartId = cartId;
        return callback(...newArgs);
    };

    getCreateEmptyCartMutation = (args, callback, instance) => {
        // if (window.location.pathname.indexOf('checkout') === 1 || window.currentRoute !== 'product') {
        //     return callback(...args);
        // }
        return new Field('createEmptyCart')
            .addArgument('buyNow', 'String', 'true');
    };

    _getStoreConfigFields = (args, callback) => {
        const originalFields = callback(...args);
        return [
            ...originalFields,
            'magenear_buynow_button_label'
        ];
    };

}

const {
    componentDidMount,
    renderAddToCartButton,
    addProductToCart,
    getCreateEmptyCartMutation,
    _getStoreConfigFields
} = new BuyNowPlugin();

export default {
    'Component/Router/Container': {
        'member-function': {
            componentDidMount
        }
    },
    'Component/Product/Component': {
        'member-function': {
            renderAddToCartButton: renderAddToCartButton
        }
    },
    'Store/Cart/Dispatcher': {
        'member-function': {
            addProductToCart
        }
    },
    'Query/Cart/Query': {
        'member-function': {
            getCreateEmptyCartMutation
        }
    },
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields
        }
    }
};
